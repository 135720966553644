<!--@Time : 2021/11/11 10:50-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Temperature</v-card-title>
					<v-container>
						<div id="container_temperature">
							<Loading
								v-if="!temperature_data.length"
								:loading="loading"
							></Loading>
						</div>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Relative Humidity</v-card-title>
					<v-container>
						<div id="container_humidity">
							<Loading
								v-if="!humidity_data.length"
								:loading="loading"
							></Loading>
						</div>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Wind Velocity</v-card-title>
					<v-container>
						<div id="container_wind">
							<Loading
								v-if="!wind_data.length"
								:loading="loading"
							></Loading>
						</div>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Wind Direction</v-card-title>
					<v-container>
						<div id="container_wind_direction">
							<Loading
								v-if="!wind_direction_data.length"
								:loading="loading"
							></Loading>
						</div>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>PM2.5</v-card-title>
					<v-container>
						<div id="container_pm">
							<Loading
								v-if="!pm_data.length"
								:loading="loading"
							></Loading>
						</div>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Solar Radiation</v-card-title>
					<v-container>
						<div id="container_radiation">
							<Loading
								v-if="!radiation_data.length"
								:loading="loading"
							></Loading>
						</div>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Header from "../../components/Header";
import { Chart } from "@antv/g2";
import Loading from "@/components/Loading";
export default {
	name: "Weather",
	components: { Loading, Header },
	data() {
		return {
			data: [],
			wind_chart: null,
			wind_direction_chart: null,
			temperature_chart: null,
			humidity_chart: null,
			pm_chart: null,
			radiation_chart: null,
			wind_data: [],
			wind_direction_data: [],
			temperature_data: [],
			humidity_data: [],
			pm_data: [],
			radiation_data: [],
			loading: "loading...",
		};
	},

	created() {},

	mounted() {
		this.setWindChart();
		this.setWindDirectionChart();
		this.setTemperatureChart();
		this.setHumidityChart();
		this.setPmChart();
		this.setRadiationChart();
		this.initWebSocket();
	},

	beforeDestroy() {
		//离开路由之前断开websocket连接
		this.websock.close();
    this.temperature_chart.destroy();
    this.pm_chart.destroy();
    this.humidity_chart.destroy();
    this.wind_direction_chart.destroy();
    this.radiation_chart.destroy();
    this.wind_chart.destroy();
	},

	watch: {},

	computed: {},

	methods: {
		async setWindChart() {
			this.wind_chart = new Chart({
				container: "container_wind",
				autoFit: true,
				height: 500,
			});
			this.wind_chart.data(this.wind_data);
			this.wind_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				value: {
					nice: true,
					alias: "Wind Velocity (m/s)",
				},
			});

			this.wind_chart.axis("value", {
				title: {
					text: "Wind Velocity (m/s)",
				},
				/* label: {
          formatter:(val) =>  {
              return val + 'm/s';
            },
        }, */
			});

			this.wind_chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});

			this.wind_chart.line().position("time*value").shape("smooth");
			this.wind_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.wind_chart.render();
		},

		async setWindDirectionChart() {
			this.wind_direction_chart = new Chart({
				container: "container_wind_direction",
				autoFit: true,
				height: 500,
			});
			this.wind_direction_chart.data(this.wind_direction_data);
			this.wind_direction_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				value: {
					nice: true,
					alias: "Wind Direction",
          values:["N 0°","NE 45°", "E 90°", "SE 135°", "S 180°", "SW 225°", "W 270°", "NW 315°"]
				},
			});

			this.wind_direction_chart.axis("value", {
				title: {
					text: "Wind Direction (°)",
				},
			});

			this.wind_direction_chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});
			this.wind_direction_chart
				.line()
				.position("time*value")
				.shape("smooth");
			this.wind_direction_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.wind_direction_chart.render();
		},

		async setTemperatureChart() {
			this.temperature_chart = new Chart({
				container: "container_temperature",
				autoFit: true,
				height: 500,
			});
			this.temperature_chart.data(this.temperature_data);
			this.temperature_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				value: {
					nice: true,
					alias: "Temperature (°C)",
				},
			});

			this.temperature_chart.axis("value", {
				title: {
					text: "Temperature (°C)",
				},
				/* label: {
					formatter: (val) => {
						return val + "°C";
					},
				}, */
			});

			this.temperature_chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});

			this.temperature_chart
				.line()
				.position("time*value")
				.shape("smooth");
			this.temperature_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.temperature_chart.render();
		},

		async setHumidityChart() {
			this.humidity_chart = new Chart({
				container: "container_humidity",
				autoFit: true,
				height: 500,
			});

			this.humidity_chart.data(this.humidity_data);
			this.humidity_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				value: {
					nice: true,
					alias: "Relative Humidity (%)",
				},
			});

			this.humidity_chart.axis("value", {
				title: {
					text: "Relative Humidity (%)",
				},
				/* label: {
					formatter: (val) => {
						return val + "%";
					},
				}, */
			});

			this.humidity_chart.tooltip({
				showCrosshairs: true,
				shared: true,
				showMarkers: false,
			});

			this.humidity_chart.line().position("time*value").shape("smooth");

			this.humidity_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.humidity_chart.render();
		},

		async setPmChart() {
			this.pm_chart = new Chart({
				container: "container_pm",
				autoFit: true,
				height: 500,
			});

			this.pm_chart.data(this.pm_data);
			this.pm_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				value: {
					nice: true,
					alias: "PM2.5 (μg/m³)",
				},
			});

			this.pm_chart.axis("value", {
				title: {
					text: "PM2.5 (μg/m³)",
				},
				/* label: {
					formatter: (val) => {
						return val + "μg/m³";
					},
				}, */
			});

			this.pm_chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});

			this.pm_chart.line().position("time*value").shape("smooth");
			this.pm_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.pm_chart.render();
		},

		async setRadiationChart() {
			this.radiation_chart = new Chart({
				container: "container_radiation",
				autoFit: true,
				height: 500,
			});

			this.radiation_chart.data(this.radiation_data);
			this.radiation_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				value: {
					nice: true,
					min: 0,
					max: 800,
					alias: "Solar Radiation (W/m²)",
				},
			});

			this.radiation_chart.axis("value", {
				title: {
					text: "Solar Radiation (W/m²)",
				},
				/* label: {
					formatter: (val) => {
						return val + "W/m²";
					},
				}, */
			});

			this.radiation_chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});

			this.radiation_chart.line().position("time*value").shape("smooth");
			this.radiation_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.radiation_chart.render();
		},

		//初始化websocket
		async initWebSocket() {
			const url = `${process.env.VUE_APP_WS_URL}/ws/standing/?token=${this.$store.getters.token}`;
			this.websock = new WebSocket(url);
			this.websock.onmessage = this.WebsocketOnMessage;
			this.websock.onopen = this.WebsocketOnOpen;
			this.websock.onerror = this.WebsocketOnError;
			this.websock.onclose = this.WebsocketClose;
		},
		WebsocketOnOpen(e) {
			console.log("建立连接", e);
			//连接建立之后执行send方法发送数据
			let actions = { type_id: "2" };
			this.WebsocketSend(JSON.stringify(actions));
		},
		WebsocketOnError() {
			//连接建立失败重连
			this.initWebSocket();
		},
		WebsocketOnMessage(event) {
			//数据接收
			const data = JSON.parse(event.data);
			this.wind_data = [];
			this.wind_direction_data = [];
			this.temperature_data = [];
			this.humidity_data = [];
			this.pm_data = [];
			this.radiation_data = [];
			data.forEach((item) => {
				this.wind_data.push({
					time: item["time"],
					value: item["wind_value"],
				});
				this.wind_direction_data.push({
					time: item["time"],
					value: item["direction"],
				});
				this.temperature_data.push({
					time: item["time"],
					value: item["temperature_value"],
				});
				this.humidity_data.push({
					time: item["time"],
					value: item["humidity_value"],
				});
				this.pm_data.push({
					time: item["time"],
					value: item["pm_value"],
				});
				this.radiation_data.push({
					time: item["time"],
					value: item["radiation"],
				});
			});
			this.wind_chart.changeData(this.wind_data);
			this.wind_direction_chart.changeData(this.wind_direction_data);
			this.temperature_chart.changeData(this.temperature_data);
			this.humidity_chart.changeData(this.humidity_data);
			this.pm_chart.changeData(this.pm_data);
			this.radiation_chart.changeData(this.radiation_data);
		},
		WebsocketSend(Data) {
			//数据发送
			this.websock.send(Data);
		},
		WebsocketClose(e) {
			//关闭
			console.log("断开连接", e);
		},
	},
};
</script>